import SanitizedHTML from '@components/html/Sanitized'

export default function SalonDescription (
    { salon: { dealer: { html_description }}}) {

    return html_description
        ? <div className="container" style={{ padding: '50px 0' }}>
            <SanitizedHTML html={ html_description } />
          </div>

        : null
}