import Partner from './PartnerSalonComponent'
import Layout from '@components/layout/Salon'
import SalonHead from '@components/salon/SalonHead'
import SalonDescription from '@components/salon/SalonDescription'
import SalonOffer from '@components/salon/SalonOffer'
import Seo from 'src/seo/components/Seo'
import JsonLdSalon from 'src/seo/jsonld/JsonLdSalon'
import salonLink from '@utils/links/salonLink'

export default function SalonComponent (props) {
    const isPartner = Boolean(props.salon.is_partner)
    return isPartner
        ? <Partner {...props} />
        : <Salon {...props} />
}

function Salon ({ salon, prefetchedOffers, seo, subdomain, visitor }) {
    const { _id, dealer = {}, ad_count = {}} = salon
    const { content = {}} = dealer
    const contentWithHeavies = { ...content, heavies: true }  // <- always include heavies

    seo.canonical = salonLink(subdomain)

    return (
        <Layout>
            <Seo seo={seo} />
            <JsonLdSalon salon={salon} />
            <div className="container min-height">
                <SalonHead salon={salon} noLink="true" visitor={visitor} />
                <SalonDescription salon={salon} />
            </div>

            <SalonOffer
                adCount={ad_count}
                salonId={_id}
                content={contentWithHeavies}
                prefetchedOffers={prefetchedOffers} />
        </Layout>
    )
}