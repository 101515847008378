import AdvancedImage from '@components/image/WithFallback'
import ImageWrapper from '@components/image/WrapperFilling'

const Photo = ({ alt, src, fallback }) => {
    return (
        <ImageWrapper>
            <AdvancedImage
                src={src}
                fallback={fallback}
                alt={alt || ''}
                layout="fill"
                objectFit="cover" />
        </ImageWrapper>
    )
}

export default Photo