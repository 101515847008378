import { StaticAdList } from '@components/lists/AdList'
import Sort from '@components/select/SortAlt'
import ButtonWrapper from '@components/salon/ButtonWrapper'
import useT from '@hooks/useTranslation'
import withSalonOffers from '@hoc/withSalonOffers'

const SalonOffer = ({ offers, pagination, changePage, changeSort, sortValue }) => {
    const setPage = (value) => {
        document
            .getElementById('salon-list-heading')
            .scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })

        changePage(value)
    }

    return (
        <div>
            <br/>
            <Sort onChange={changeSort} defaultValue={sortValue} />
            <div style={{ display: 'grid', gap: 30 }}>
                <br />
                <StaticAdList offers={offers} />
                <SimplePagination pagination={pagination} onChange={setPage} />
                <br />
            </div>
        </div>
    )
}

function SimplePagination ({ pagination, onChange }) {
    if (!pagination) return null
    const { totalPages, page } = pagination
    if (totalPages < 1) return null
    return (
        <ButtonWrapper center>
            { page > 1 &&
                <button onClick={() => onChange(page - 1)}>
                    {useT('Poprzednia strona')}</button> }

            { page < totalPages &&
                <button onClick={() => onChange(page + 1)}>
                    {useT('Następna strona')}</button> }
        </ButtonWrapper>
    )
}

export default withSalonOffers(SalonOffer)