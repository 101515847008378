import styles from 'styles/SalonButtonWrapper.module.css'
import mix from '@utils/styles/mix'

const ButtonWrapper = ({ children, center }) => {
    const className = center
        ? mix([styles.wrapper, styles.center])
        : styles.wrapper

    return (
        <div className={className}>
            { children }
        </div>
    )
}

export default ButtonWrapper