export const CONTENT_MAP = {
    brand_new:   'Nowe samochody',
    used:        'Używane samochody',
    for_rent:    'Wynajem pojazdów',
    motorcycles: 'Motocykle',
    service:     'Serwis pojazdów',
    parts:       'Części i akcesoria',
    tools:       'Narzędzia',
    trucks:      'Samochody dostawcze',
    heavies:     'Samochody ciężarowe',
    agri:        'Maszyny rolnicze',
}

export const AD_COUNT_KEYS = {
    brand_new: 'car_ads_new',
    used: 'car_ads_used',
    for_rent: 'car_ads_rent',
    motorcycles: 'motorcycle_ads',
    service: 'service_ads',
    parts: 'part_ads',
    tools: 'tool_ads',
    trucks: 'truck_ads',
    heavies: 'ciezarowes',
    agri: 'agri_ads',
}