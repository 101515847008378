import { useState, useEffect, useMemo } from 'react'
import useSWR from 'swr'
import { apiFetcherPost as apiFetcher } from '@api/apiFetcher'
import SpinnerBlock from '@components/spinner/SpinnerBlock'

const withSalonOffers = (TargetComponent) =>
    (props) => {
        const { salonId, activeContent, prefetchedOffers } = props

        const [isFirstRender, setIsFirstRender] = useState(true)
        const [sort, setSort] = useState('Najnowsze')
        const [page, setPage] = useState(0)

        useEffect(() => {
            if (isFirstRender) {
                setIsFirstRender(false)
            } else {
                setPage(1)
            }
        }, [activeContent])

        const shouldFetch = page > 0
        const getUrl = () => `/api/salon/${salonId}/offers/${activeContent}/${page}/${sort || 'Najnowsze'}`
        const url = useMemo(() => getUrl(), [
            salonId,
            activeContent,
            page,
            sort,
        ])

        const changeSort = ({target:{value}}) => {
            setSort(value)
            setPage(1)
        }

        const { data } = useSWR(shouldFetch && url, apiFetcher)

        const { ads, pagination } = shouldFetch
            ? data || {}
            : prefetchedOffers

        return ads
            ? <TargetComponent
                offers={ads}
                pagination={pagination}
                changePage={setPage}
                changeSort={changeSort}
                sortValue={sort} />

            : <SpinnerBlock height={400} />
    }

export default withSalonOffers