import Select from '@components/select/Select'
import { NO_LOCATION_SORT_OPTIONS } from '@components/select/values/sortOptions'

// used in salons (doesn't use router):
const Sort = ({ onChange, defaultValue }) => (
    <Select
        name="sortuj"
        label="Sortuj"
        options={NO_LOCATION_SORT_OPTIONS}
        defaultOption={defaultValue}
        onChange={onChange}
    />
)

export default Sort