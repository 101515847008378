import Triptic from '@components/display/Triptic'
import Photo from '@components/display/photos/SinglePhoto'
import UserDetails from '@components/display/user/Details'
import UserLocation from '@components/display/user/Location'

export default function SalonHead ({ salon, noLink,
    salon: { _id, dealer: { firm_name }},
    visitor }) {

    if (noLink) salon.noLink = true

    return (
        <div style={{ borderTop: '1px solid var(--input-border-c)' }}>
            <Triptic>
                <div style={{ minHeight: 400 }}>
                    <Photo
                        src={`/api/photos/salon_photo/${_id}`}
                        alt={firm_name} />
                </div>
                <UserDetails user={salon} visitor={visitor} nocontact salon />
                <UserLocation user={salon} salon />
            </Triptic>
        </div>
    )
}