import { useCallback, useState, useEffect } from 'react'
import NavLinkButton from '@components/shared/NavLinkButton'
import useT from '@hooks/useTranslation'

const useGradualFetch = (baseUrl, fetcher, limit = 15, initialData) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(initialData || [])
    const [done, setDone] = useState(false)

    const fetch = useCallback((prevData) => {
        if (loading) return

        setLoading(true)

        const url = baseUrl
            .replace(':skip', (prevData || data).length)
            .replace(':limit', limit)

        fetcher(url)
            .then(res => {
                setLoading(false)
                if (res.length < limit) setDone(true)
                setData((prevData || data).concat(res))
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })

    }, [baseUrl, data?.length])

    const refresh = () => {
        setDone(false)
        fetch([])
    }

    useEffect(() => {
        if (!initialData) {
            refresh()
        }
    }, [baseUrl])

    const button = (
        <div><br/><NavLinkButton color="blue" onClick={() => fetch()}>
            {useT('Wczytaj więcej')}</NavLinkButton></div>
    )

    const hideButton = loading || done

    return [data, loading, hideButton
        ? null
        : button, refresh]
}

export default useGradualFetch