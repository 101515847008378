import { useState } from 'react'
import OfferContent from '@components/salon/SalonOfferContent'
import useT from '@hooks/useTranslation'
import ButtonWrapper from '@components/salon/ButtonWrapper'
import { CONTENT_MAP, AD_COUNT_KEYS } from './constants/salonOffer'

const SalonOffer = ({ adCount, salonId, content, prefetchedOffers }) => {
    const availableContent = Object
        .keys(content)
        .filter(key => content[key] === true)
        .filter(key => Boolean(adCount[AD_COUNT_KEYS[key]]))

    const noContentToDisplay =
           (availableContent.length < 1)
        || (availableContent === 1 && prefetchedOffers.length < 1)

    if (noContentToDisplay) return null

    const [activeTab, setActiveTab] = useState(0)
    const activeContent = availableContent[activeTab]

    return (
        <div className="container">
            <hr/>
            <br/>
            <h2 id="salon-list-heading">{useT('Pełna oferta dealera')}</h2>
            <OfferButtons
                activeTab={activeTab}
                availableContent={availableContent}
                setActiveTab={setActiveTab} />
            <OfferContent
                salonId={salonId}
                activeContent={activeContent}
                prefetchedOffers={prefetchedOffers} />
        </div>
    )
}

function OfferButtons ({ activeTab, availableContent, setActiveTab }) {
    return (
        <ButtonWrapper>
            { availableContent.map((key, i) => (
                <button key={key} onClick={() => setActiveTab(i)} active={activeTab === i
                    ? "true"
                    : undefined}>

                    {useT(CONTENT_MAP[key])}
                </button>
            )) }
        </ButtonWrapper>
    )
}

export default SalonOffer