import Business from '@constants/Business'
import salonLogo from '@utils/links/salonLogo'
import salonLink from '@utils/links/salonLink'

export default function salonPage (salon) {
    const { _id, dealer = {}} = salon
    const { firm_address = [], firm_name = '', firm_phone = [], subdomain = '' } = dealer
    return {
        '@context': 'http://schema.org',
        '@type': 'Car dealer',
        'image': Business.host + salonLogo(_id),
        'name': firm_name,
        'address': {
            '@type': 'PostalAddress',
            'streetAddress': firm_address[0],
        },
        'url': salonLink(subdomain),
        'telephone': firm_phone[0],
    }
}