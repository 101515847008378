import { useMemo } from 'react'
import useGradualFetch from '@hooks/useGradualFetch'
import apiFetcher from '@api/apiFetcher'

const API = '/api/user/partner_content/items/:type/partner/:id/:skip/:limit'
const getAPIPath = (type, id) => API
    .replace(':type', type)
    .replace(':id', id)

const withPartnerItems =
    TargetComponent =>
        props => {
            const { type, salon, items: prefetchedItems } = props
            const apiPath = useMemo(() => getAPIPath(type, salon._id),
                [type, salon?._id])

            const [items, loading, button] = useGradualFetch(
                apiPath, apiFetcher, 6, prefetchedItems)

            if (loading && items.length < 1) return null

            return (
                <TargetComponent {...props}
                    items={items}
                    button={button} />
            )
        }

export default withPartnerItems