import Layout from '@components/layout/Salon'
import SalonHead from '@components/salon/SalonHead'
import SalonDescription from '@components/salon/SalonDescription'
import PartnerItems from '@components/salon/PartnerItems'
import Seo from 'src/seo/components/Seo'
import TYPES from '@pages/user/partner/constants/types'
import salonLink from '@utils/links/salonLink'

const PartnerSalonComponent = ({ salon, subdomain, seo,
    prefetchedOffers: { articles, products }, visitor }) => {

        seo.canonical = salonLink(subdomain)

        return (
            <Layout>
                <Seo seo={seo} />
                <div className="container min-height">
                    <SalonHead salon={salon} noLink="true" visitor={visitor} />
                    <SalonDescription salon={salon} />
                </div>

                <PartnerItems salon={salon} text="Produkty" subdomain={subdomain}
                    type={TYPES.PRODUCT} items={products} />

                <PartnerItems salon={salon} text="Artykuły" subdomain={subdomain}
                    type={TYPES.ARTICLE} items={articles} />

                <br/><br/>
            </Layout>
        )
    }

export default PartnerSalonComponent