import ListOut from '@components/shared/ListOut'
import PartnerListItem from '@components/salon/PartnerListItem'
import withPartnerItems from '@components/salon/hoc/withPartnerItems'
import useT from '@hooks/useTranslation'

import styles from '@components/salon/styles/PartnerItemsContainer.module.css'

const PartnerItems = ({ type, subdomain, items, text, button }) => {
    if (items?.length < 1) return null

    return (
        <div className="container">
            <h2>{useT(text)}</h2>
            <Container>
                <ListOut id="_id" type={type} subdomain={subdomain}
                    items={items} Component={PartnerListItem} />
            </Container>

            <br/>
            { button }
            <br/><br/><br/>
        </div>
    )
}

function Container ({ children }) {
    return (
        <div className={styles.container}>
            { children }
        </div>
    )
}

export default withPartnerItems(PartnerItems)